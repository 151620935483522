/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@components/theme';
import classNames from 'classnames';
import { LogoWithQuote } from '@components/shared/PressLogos/Logos';
import CarouselNavigator from '@components/shared/carousels/CarouselNavigator/CarouselNavigator';
import styles from './Press.module.scss';

export default function Press() {
  const theme = useTheme();
  const [selectedIdx, setSelectedIdx] = useState(0);
  const interval = useRef<number | null>(null);

  const quote = LogoWithQuote[selectedIdx];

  useEffect(() => {
    interval.current = window.setInterval(() => {
      setNextIndex();
    }, 10000);

    return () => {
      return clearQuotesInterval();
    };
  }, []);

  function setNextIndex() {
    return setSelectedIdx((idx) => {
      return (idx + 1) % LogoWithQuote.length;
    });
  }

  function setPreviousIndex() {
    return setSelectedIdx((idx) => {
      if (idx - 1 < 0) {
        return LogoWithQuote.length - 1;
      }
      return idx - 1;
    });
  }

  function clearQuotesInterval() {
    if (interval.current) {
      window.clearInterval(interval.current);
      interval.current = null;
    }
  }

  return (
    <section className={ styles.Container }>
      {/*<div className={ classNames(theme.styles.Overline, styles.Overline) }>*/}
      {/*  press*/}
      {/*</div>*/}
      <div className={styles.QuoteAndLogo}>
        <blockquote className={ classNames(theme.styles.Title2, styles.Quote) }>
          {quote.quote}
        </blockquote>
        <div className={classNames(styles.LogoMobile, styles.IsSelected)}><quote.Logo /></div>
      </div>

      <div className={ classNames(styles.Logos) }>
        {LogoWithQuote.map((logo, i) => (
          <button
            type="button"
            onClick={() => {
              clearQuotesInterval();
              setSelectedIdx(i);
            }}
            key={logo.key}
            className={classNames({
              [styles.IsSelected]: LogoWithQuote[selectedIdx].key === logo.key
            })}
          >
            <logo.Logo />
          </button>
        ))}
      </div>
      <CarouselNavigator
        className={styles.Pagination}
        onClickNext={() => {
          clearQuotesInterval();
          setNextIndex();
        }}
        onClickPrev={() => {
          clearQuotesInterval();
          setPreviousIndex();
        }}
      />
    </section>
  );
}
